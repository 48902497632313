import React, { Fragment } from "react";
import Header from "../Header/Header";
import About from "./About/About";
import Banner from './Banner/Banner';
import Cta from "./Cta/Cta";
import Opinion from "./Opinion/Opinion";
import OfficeLocation from './OfficeLocation/OfficeLocation';
import Register from './Register/Register';
import BrowseProperty from "./BrowseProperty/BrowseProperty";
import { useWindowSize } from '../../hooks/window-size'
import Footer from "../Footer/Footer";

const Home = () => {
    const [windowWidth] = useWindowSize();

    return(
        <Fragment>
            <Header />
            <Banner />
            <Cta />
            {windowWidth > 991 && <About />}
            <Opinion />
            {windowWidth < 992 && <About />}
            <OfficeLocation />
            <Register />
            {windowWidth > 1199 && <BrowseProperty /> }
            <Footer />
        </Fragment>
    )
}
export default Home;
