import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./Youtube.scss"

const NewsForm = props => {
  return (
    <section className="Youtube">
      <Row>
        <Col>
          <div className="valuation-banner">
            <div className="valuation-details">
              <i className="icon-email"></i>
              {props.type === "Research" ? (
                <h3>
                  Subscribe to receive{" "}
                  <span className="highlight">
                    DNG Research & Analysis Updates
                  </span>
                </h3>
              ) : (
                <h3>
                  Subscribe for{" "}
                  <span className="highlight">Insight & opinion updates</span>
                </h3>
              )}

              {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit.</p> */}
            </div>
            <div className="valuation-cta">
              <a
                href="javascript:;"
                onClick={() => props.executeScrolljoin()}
                className="btn"
              >
                {props.type === "Research" ? "Subscribe here" : "join now"}
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default NewsForm
