import React from 'react';
import Home from '../../Components/Home/Home';

const Homepage=()=>{

    return(
        <React.Fragment>
            <Home />
        </React.Fragment>
    )
}

export default Homepage;