import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import './browseProperty.scss';
// import ScrollAnimation from 'react-animate-on-scroll';

const propertyType = [
	{
		id: 1,
		propertyName: "Houses for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 2,
		propertyName: "Apartments for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 3,
		propertyName: "Flats for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 4,
		propertyName: "Properties for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	}
] 
const propertyBedroom = [
	{
		id: 1,
		propertyName: "1 bedroom properties for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 2,
		propertyName: "2 bedroom properties for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 3,
		propertyName: "3 bedroom properties for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 4,
		propertyName: "4 bedroom properties for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 5,
		propertyName: "5 bedroom properties for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	},
	{
		id: 6,
		propertyName: "6 bedroom properties for sale in Balbriggan, Dublin",
		propertyUrl: "/"
	}
] 
const propertyArea = [
	{
		id: 1,
		propertyName: "Properties for sale in Arbour Hill, Dublin",
		propertyUrl: "/"
	},
	{
		id: 2,
		propertyName: "Properties for sale in Blackrock, Dublin",
		propertyUrl: "/"
	},
	{
		id: 3,
		propertyName: "Properties for sale in Crumlin, Dublin",
		propertyUrl: "/"
	},
	{
		id: 4,
		propertyName: "Properties for sale in Kimmage, Dublin",
		propertyUrl: "/"
	},
	{
		id: 5,
		propertyName: "Properties for sale in Oldbawn, Dublin",
		propertyUrl: "/"
	}
] 

const BrowseProperty = () => {
	return (
		<section className="browse-property">
			<Container>
				<Row>
					<Col sm="12">
						<h3>Browse properties for sale in: Balbriggan, Dublin</h3>
						{/* <ScrollAnimation animateIn="fadeInUp"> */}
							<div className="property-block d-flex flex-wrap justify-content-between">
								<div className="properties-wrap">
									<h4>By Property Type</h4>
									<ul>
										{propertyType.map(property => (
											<li key={property.id}>
												<Link to={property.propertyUrl}>{property.propertyName}</Link>
											</li>
										))}
									</ul>
								</div>
								<div className="properties-wrap">
									<h4>By Bedrooms</h4>
									<ul>
										{propertyBedroom.map(property => (
											<li key={property.id}>
												<Link to={property.propertyUrl}>{property.propertyName}</Link>
											</li>
										))}
									</ul>
								</div>
								<div className="properties-wrap">
									<h4>Nearby Areas</h4>
									<ul>
										{propertyArea.map(property => (
											<li key={property.id}>
												<Link to={property.propertyUrl}>{property.propertyName}</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
						{/* </ScrollAnimation> */}
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default BrowseProperty;
